<template>
  <div>
    <!-- <v-overlay :value="!loaded">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay> -->
    <v-btn
      color="primary"
      :loading="loadingPdf"
      :disabled="!loaded"
      class="mb-2"
      @click="printPdf()"
      >Download PDF</v-btn
    >

    <v-sheet
      v-if="order && patient && appointment && partner && proxy"
      elevation="1"
      width="1063"
      style="padding: 96px;"
    >
      <v-progress-linear
        v-if="!loaded"
        color="error"
        indeterminate
        rounded
        height="6"
      ></v-progress-linear>
      <div ref="orderPDF" id="orderPDF">
        <div class="html2pdf__page-break">
          <v-row>
            <v-col style="color: black !important;" cols="6">
              <div><strong>Patient Information:</strong></div>
              <div>Name: {{ patient.fname }} {{ patient.lname }}</div>
              <div>
                {{ `DOB: ${formatDate(patient.medicalcard.birthdate)}` }}
              </div>
              <div v-if="patient.email">Email: {{ patient.email }}</div>
              <div v-if="patient.phoneNumber">
                Phone: {{ patient.phoneNumber }}
              </div>
              <div v-if="appointment.patientAddress">
                Address: {{ appointment.patientAddress }}
              </div>
            </v-col>
            <v-col
              style="color: black !important;"
              cols="6"
              class="d-flex flex-column align-end"
            >
              <div>
                <div><strong>Partner Information:</strong></div>
                <div v-if="partner.email">Email: {{ partner.email }}</div>
                <div v-if="partner.phoneNumber">
                  Phone: {{ partner.phoneNumber }}
                </div>
                <div v-if="partner.address">Contact: {{ partner.contact }}</div>
                <div v-if="partner.address">Address: {{ partner.address }}</div>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col style="color: black !important;" cols="12">
              <div><strong>Provider Information:</strong></div>
              <div>{{ order.doctorName }}</div>
              <div>Date: {{ formatDate(order.date) }}</div>
              <div>Time: {{ formatTime(order.date) }}</div>
            </v-col>
          </v-row>
          <v-row>
            <v-col style="color: black !important;" cols="12">
              <div>
                <span style="font-size: 28px;">
                  {{ order.isElectronicOrder ? '&#9745;' : '&#9744;' }}</span
                >
                VPExamHomecare Order
              </div>
            </v-col>
            <v-col style="color: black !important;" cols="12">
              <div>
                Date of Encounter: {{ formatEncounter(order.encounterDate) }}
              </div>
            </v-col>
            <v-col style="color: black !important;" cols="12">
              <div>
                <span style="font-size: 28px;">
                  {{ order.isFace2Face ? '&#9745;' : '&#9744;' }}</span
                >
                Face to Face Encounterwas completed:
                {{ formatEncounter(order.encounterDate) }}
              </div>
            </v-col>
            <v-col
              style="color: black !important;"
              cols="12"
              class="d-flex align-center pb-0 mb-0"
            >
              <div>
                Skilled Discipline Ordered:
              </div>
            </v-col>
            <v-col style="color: black !important;" cols="4">
              <div>
                <span style="font-size: 28px;">
                  {{ order.isNursing ? '&#9745;' : '&#9744;' }}</span
                >
                Skilled Nursing (RN/LPN)
              </div>
            </v-col>
            <v-col style="color: black !important;" cols="4">
              <div>
                <span style="font-size: 28px;">
                  {{ order.isHomeAide ? '&#9745;' : '&#9744;' }}</span
                >
                Home Health Aide
              </div>
            </v-col>
            <v-col style="color: black !important;" cols="12"
              >Medical Diagnosis: {{ order.diagnosis }}</v-col
            >
            <v-col style="color: black !important;" cols="12">
              <div>
                <span style="font-size: 28px;">
                  {{ order.isNecessity ? '&#9745;' : '&#9744;' }}</span
                >
                Medical Necessity for Homecare (based on clinical findings)
              </div>
            </v-col>
            <v-col style="color: black !important;" cols="12">
              <div class="info-text">
                {{ order.homecare }}
              </div>
            </v-col>
            <v-col style="color: black !important;" cols="12">
              <div>
                <span style="font-size: 28px;">
                  {{ order.isHomeBound ? '&#9745;' : '&#9744;' }}</span
                >
                Homebound Status:
              </div>
            </v-col>
            <v-col style="color: black !important;" cols="12">
              <div class="info-text">
                {{ order.homeBoundStatus }}
              </div>
            </v-col>
            <v-col style="color: black !important;" cols="12">
              <div>
                <span style="font-size: 28px;">
                  {{ order.isFace2FaceReviewed ? '&#9745;' : '&#9744;' }}</span
                >
                Face to Face Completed and Home Care Orders Reviewed:
              </div>
            </v-col>
            <v-col style="color: black !important;" cols="12">
              <div class="info-text">
                {{ order.homeCareReviewed }}
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col style="color: black !important;" cols="12">
              <img :src="proxy.signature" style="max-width: 400px" alt="" />
              <div>{{ order.doctorName }}</div>
              <div>{{ formatTimeStamp(order.createdAt) }}</div>
            </v-col>
          </v-row>
        </div>
      </div>
    </v-sheet>
  </div>
</template>
<script>
import * as fb from '@/firebase'
import moment from 'moment'
import ProxyLayout from '@/layouts/ProxyLayout'
import PartnerLayout from '@/layouts/PartnerLayout'
import html2pdf from 'html2pdf.js'
import { saveAs } from 'file-saver'

export default {
  name: 'PatientPdf',
  props: ['user'],
  data() {
    return {
      currentUser: null,
      loaded: false,
      loadingPdf: false,
      orderId: null,
      order: null,
      patient: null,
      partner: null,
      proxy: null,
      appointment: null
    }
  },
  watch: {
    user: {
      immediate: true,
      handler() {
        if (this.user) {
          this.currentUser = this.user

          if (fb.auth.currentUser.displayName == 'partner') {
            this.$emit(`update:layout`, PartnerLayout)
          } else {
            this.$emit(`update:layout`, ProxyLayout)
          }
        }
      }
    },
    '$route.params': {
      immediate: true,
      handler() {
        if (this.$route.params.orderId) {
          this.orderId = this.$route.params.orderId
        }
      }
    }
  },
  async mounted() {
    await this.$bind(
      'order',
      fb.db.collection(`Electronic_Orders`).doc(this.orderId)
    )
    await this.$bind('patient', fb.db.doc(`Patients/${this.order.patientId}`))
    await this.$bind(
      'appointment',
      fb.db.doc(
        `Practices/${this.order.practiceId}/Appointments/${this.order.appointmentId}`
      )
    )
    await this.$bind('partner', fb.db.doc(`Partners/${this.order.partnerId}`))

    await this.$bind('proxy', fb.db.doc(`Proxies/${this.order.doctorId}`))

    this.loaded = true
    this.loadingPdf = false
  },

  methods: {
    async printPdf() {
      this.loadingPdf = true
      window.scrollTo(0, document.body.scrollHeight)
      window.scrollTo(0, 0)
      var opt = {
        margin: 0.5,
        filename: `${this.patient.fname}-${
          this.patient.lname
        }-${moment().format('MM-DD-YYYY')}.pdf`,
        image: { type: 'jpeg', quality: 1 },
        html2canvas: {
          dpi: 96,
          letterRendering: true,
          useCORS: true,
          scale: 1,
          removeContainer: false
        },
        jsPDF: {
          unit: 'in',
          format: 'legal',
          orientation: 'portrait',
          compress: false,
          precision: 16
        }
      }
      this.element = document.getElementById('orderPDF')

      let file1 = await html2pdf()
        .set(opt)
        .from(this.element)
        .toPdf()
        .output('blob')
        .then(data => {
          return data
        })
      this.saveZipOne(file1)
      this.loadingPdf = false
    },
    saveZipOne(file1) {
      saveAs(
        file1,
        `${this.patient.fname}.${this.patient.lname}.${
          this.patient.medicalcard.birthdate
            ? moment(this.patient.medicalcard.birthdate.toDate()).format(
                'MM-DD-YYYY'
              )
            : 'na'
        }.pdf`
      )
    },
    formatDate(date) {
      return moment(date.toDate()).format('MM/DD/YYYY')
    },
    formatTime(date) {
      return moment(date.toDate()).format('HH:mm a')
    },
    formatEncounter(date) {
      return moment(date.toDate()).format('DD - MMMM - YYYY')
    },
    formatTimeStamp(date) {
      return moment(date.toDate()).format('MM/DD/YYYY HH:mm a')
    }
  }
}
</script>
